/* purgecss start ignore */
@tailwind base;

@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

body {
    font-family: 'Inter', sans-serif;
    @apply bg-gray-200;
}

a {
    @apply duration-500;
    @apply ease-in-out;
    @apply text-blue-500;
    @apply transition-all;
}

a:hover, a:focus {
    @apply text-blue-400;
}


.spinning {
    animation: spin 1.25s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
